import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    public toastController: ToastController,
  ) {
  }

  async success(message: string) {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2000,
      message: message,
    });
    await toast?.present();
    toast.onclick = (event) => {
      toast.dismiss();
    };
  }

  async info(message: string) {
    const toast = await this.toastController.create({
      color: 'primary',
      duration: 5000,
      message: message,
    });
    await toast?.present();
    toast.onclick = (event) => {
      toast.dismiss();
    };
  }

  async warning(message: string, toastSettings = null) {
    const toast = await this.toastController.create({
      ...{
        color: 'warning',
        duration: 10000,
        message: message,
      },
      ...toastSettings
    });
    await toast?.present();
    toast.onclick = (event) => {
      toast.dismiss();
    };
  }

  async error(message: string) {
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 8000,
      message: message,
    });
    await toast?.present();
    toast.onclick = (event) => {
      toast.dismiss();
    };
  }
}
