import { Component, Input, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-date-time-modal',
  templateUrl: './date-time-modal.component.html',
  styleUrls: ['./date-time-modal.component.scss'],
})
export class DateTimeModalComponent {
  @ViewChild('datePicker')
  public datePicker: IonDatetime;
  @Input() public value: string;
  @Input() public minDate: string;
  @Input() public maxDate: string;
  @Input() public minuteValues: number[] = null;
  @Input() public hourValues: number[] = null;
  @Input() public showTime: boolean = true;

  constructor(
    private modalController: ModalController,
  ) {
  }

  public async onSubmitClick(): Promise<void> {
    await this.datePicker.confirm();
    await this.modalController.dismiss(this.datePicker.value);
  }

  public async onCancelClick(): Promise<void> {
    await this.modalController.dismiss();
  }
}
