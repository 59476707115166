import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Client, RatingRequest } from '../../api-clients/pyjam/client';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { PAYMENT_INTENT_TYPE } from '../reply.constants';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
})
export class TipsComponent implements OnInit, ViewWillEnter {
  public readonly PAYMENT_INTENT_TYPE = PAYMENT_INTENT_TYPE;
  public replyId: number;
  public form: FormGroup;
  public isPaymentNeeded = false;
  public currentUserId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private client: Client,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.currentUserId = this.authService.parsedToken.userId;
    this.form = new FormGroup({
      tip: new FormControl(0, []),
    });
  }

  ionViewWillEnter() {
    this.loadingService.start()
      .then(() => {
        this.replyId = this.activatedRoute.snapshot.params?.id;
        this.client.replyGet(this.replyId, true).toPromise()
          .then(async (res) => {
            if (this.currentUserId === res.data.user.id) {
              await this.navigationService.goBack();
            }
          })
          .catch(async ex => {
            // await this.httpErrorHandlerService.handleHttpException(ex);
          })
          .finally(() => {
            this.loadingService.stop().then();
          });
      });
  }

  async onContinueClick() {
    this.isPaymentNeeded = true;
  }

  async onSkipClick() {
    await this.navigationService.goBack();
  }

  getTitle(): string {
    return this.translate.instant('reply.tiping');
  }
}
