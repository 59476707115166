<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="openMenu()" *ngIf="isTaskOwner">
        <ion-icon class="menu" name="ellipsis-horizontal"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.showResult.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content ion-padding-bottom ion-padding-start ion-padding-end">
    <ion-list lines="none">
      <ion-item class="item">
        <div>
          <h2 class="item-header">{{ 'reply.showResult.description' | translate }}</h2>
          <p class="info">{{description}}</p>
        </div>
      </ion-item>
      <ion-item class="item" *ngIf="files.length > 0">
        <div>
          <h2 class="item-header">{{ 'reply.showResult.app' | translate }}</h2>
          <app-files-set [files]="files"
                         [allowEditing]="false"
                         [allowDeleting]="false">
          </app-files-set>
        </div>
      </ion-item>
    </ion-list>
  </div>
</ion-content>

<ion-footer class="ion-no-border" *ngIf="isTaskOwner">
  <ion-button *ngIf="reply?.status == ReplyStatus.COMPLITED_BY_WORKER"
              class="ion-padding-start ion-padding-end button-bottom"
              color="dark" fill="clear" expand="block"
              [appLoaderInButton]="onReplyDisputeClick.bind(this)">
    {{ 'reply.showResult.dispute' | translate }}
  </ion-button>
  <ion-button *ngIf="reply?.status == ReplyStatus.COMPLITED_BY_WORKER || reply?.status == ReplyStatus.DISPUTED"
              class="ion-margin-bottom ion-margin-start ion-padding-end button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onReplyAcceptClick.bind(this)">
    {{ 'reply.showResult.accept' | translate }}
  </ion-button>
</ion-footer>

<ion-footer class="ion-no-border" *ngIf="isTaskOwner === false">
  <ion-button *ngIf="reply?.status == ReplyStatus.COMPLITED_BY_WORKER"
              class="ion-margin button-bottom"
              color="dark"
              expand="block"
              [appLoaderInButton]="onReplyEditResultClick.bind(this)">
    {{ 'reply.showResult.editTaskResult' | translate }}
  </ion-button>

  проверка на наличие отзывов
  <ion-button *ngIf="reply?.status == ReplyStatus.DISPUTE_CLOSED_IN_FAVOR_OWNER
              || reply?.status == ReplyStatus.DISPUTE_CLOSED_IN_FAVOR_WORKER
              || reply?.status === ReplyStatus.ACCEPTED_BY_OWNER"
              class="ion-margin button-bottom"
              color="dark"
              expand="block"
              [appLoaderInButton]="onRateButtonClick.bind(this)">
    {{ 'reply.rateResult.review' | translate }}
  </ion-button>

</ion-footer>

<app-image-slider></app-image-slider>
