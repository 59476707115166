import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileResponse } from '../../api-clients/pyjam/client';
// import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { ParsedUser } from '../../auth/auth.service';

@Component({
  selector: 'app-files-set',
  templateUrl: './files-set.component.html',
  styleUrls: ['./files-set.component.scss'],
})
export class FilesSetComponent implements OnInit {
  // @ViewChild('imageSlider') imageSlider: ImageSliderComponent;

  @Input() set files(files: FileResponse[]) {
    this._files = files;
  }

  @Input() public parsedUser: ParsedUser;
  @Input() public allowDownload: boolean = true;
  @Input() public allowEditing: boolean = false;
  @Input() public allowDeleting: boolean = false;
  @Input() public filesDisplayMode: 'slim' | 'full';
  @Input() public filesChanged: EventEmitter<any>;
  @Output() public deleteClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() public filesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _files: FileResponse[] = [];
  private count: number = 0;

  get files(): FileResponse[] {
    return this._files;
  }

  ngOnInit() {
    this.filesChanged?.subscribe(async (): Promise<void> => {
      // this.startLoopVideo();
      // await this.imageSlider.showImages([], 0);
    });
  }

  // private async startLoopVideo(): Promise<void> {
  //   let elements: HTMLCollectionOf<HTMLVideoElement> = document.getElementsByTagName('video');
  //   for (let i = 0; i < elements.length; i++) {
  //     await elements[i].play();
  //     elements[i].muted = true;
  //   }
  // }

  public deleteProfileFile(id: number): void {
    if (this.allowDeleting) {
      this.deleteClicked.emit(id);
    }
  }

  public checkLoad(): void {
    this.count += 1;

    if (this.count === this.files.length) {
      this.filesLoaded.emit(true);
    }
  }
}
