<div id="downloadPrompt">
  <div class="downloadPrompt__description">
    <p *ngIf="platformService.isAndroid" class="downloadPrompt__text">{{ 'downloadPrompt.android' | translate }}</p>
    <p *ngIf="platformService.isIos" class="downloadPrompt__text">{{ 'downloadPrompt.ios' | translate }}</p>
    <button id="closePrompt" class="close" (click)="updateAppService.hideDownloadPrompt()">
      <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49902 7.99902L20.5001 20.0001" stroke="#1A1C1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.49902 20.001L20.5001 7.99992" stroke="#1A1C1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <ion-button (click)="updateAppService.downloadApp()" class="btn-download">{{ 'downloadPrompt.button' | translate }}</ion-button>
</div>