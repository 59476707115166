import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authCanActivate } from './auth/auth.guard';
import { LicenseAgreementComponent } from './docs/license-agreement/license-agreement.component';
import { PrivacyPolicyComponent } from './docs/privacy-policy/privacy-policy.component';
import { disputeCanActivate } from './task/dispute/dispute.guard';
import { CookiesPolicyComponent } from './docs/cookies-policy/cookies-policy.component';
import { TermsAndConditionsComponent } from './docs/terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './docs/disclaimer/disclaimer.component';
import { mobileAppCanActivate } from './pyjam-animate/mobile-app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pyjam-animate',
    pathMatch: 'full'
  },
  {
    path: 'pyjam-animate',
    loadComponent: () => import('./pyjam-animate/pyjam-animate.component').then(m => m.PyjamAnimateComponent),
    canActivate: [mobileAppCanActivate],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    canActivate: [disputeCanActivate],
  },
  {
    path: 'chats',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [disputeCanActivate],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [disputeCanActivate],
  },
  {
    path: 'avatar',
    loadChildren: () => import('./avatar/avatar.module').then(m => m.AvatarModule),
  },
  {
    path: 'reply',
    loadChildren: () => import('./reply/reply.module').then(m => m.ReplyModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'credit-info',
    loadChildren: () => import('./stripe/stripe.module').then(m => m.StripeModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [authCanActivate, disputeCanActivate],
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    data: {showMenu: false}
  },
  {
    path: 'license-agreement-en.html',
    component: LicenseAgreementComponent,
    data: {showMenu: false}
  },
  {
    path: 'privacy-policy-en.html',
    component: PrivacyPolicyComponent,
    data: {showMenu: false}
  },
  {
    path: 'cookies-policy-en.html',
    component: CookiesPolicyComponent,
    data: {showMenu: false}
  },
  {
    path: 'terms-and-conditions-en.html',
    component: TermsAndConditionsComponent,
    data: {showMenu: false}
  },
  {
    path: 'disclaimer-en.html',
    component: DisclaimerComponent,
    data: {showMenu: false}
  },
  {
    path: '**',
    redirectTo: 'avatar'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
