import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StripeReturnUrlComponent } from './stripe-return-url/stripe-return-url.component';
import { StripeRefreshUrlComponent } from './stripe-refresh-url/stripe-refresh-url.component';

const routes: Routes = [
  {
    path: 'return',
    component: StripeReturnUrlComponent,
  },
  {
    path: 'refresh',
    component: StripeRefreshUrlComponent,
  },
  {
    path: '**',
    redirectTo: 'return'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StripeRoutingModule {
}
