import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';


export const signInCanActivate: CanActivateFn = async (): Promise<boolean> => {
  const authService = inject(AuthService);
  const navController = inject(NavController);

  if (authService.parsedUser) {
    console.warn('You are already logged in. Redirecting to avatar page.');
    await navController.navigateBack('/avatar', {replaceUrl: true});
    return false;
  } else {
    return true;
  }
};
