import { Component, Input, Optional } from '@angular/core';
import { AnimationBuilder, IonRouterOutlet } from '@ionic/angular';
import { UrlTree } from '@angular/router';
import { BackButtonService } from '../../services/back-button.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() defaultHref: string | any[] | UrlTree | undefined | null;
  @Input() queryParams;
  @Input() disableClick: boolean = false;
  @Input() routerAnimation?: AnimationBuilder;

  public constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    private backButtonService: BackButtonService,
  ) {
  }


  public onClick(ev: Event = null): void {
    if (this.disableClick) {
      return;
    }

    this.backButtonService.goBack(this.defaultHref, this.queryParams);
  }
}
