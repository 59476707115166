<div class="files" id="files-list-45">
  <div class="file" *ngFor="let file of addingFiles; let i = index" (click)="imageSlider.showImages(dataFiles, i)">
    <div *ngIf="file.mimeType?.includes('image')" class="file-image">
      <img [src]="file.src" alt="">
    </div>
    <div class="file-video" *ngIf="file.mimeType?.includes('video')">
      <video id="video_background-{{file.id}}">
        <source [src]="file.src" type="video/mp4">
      </video>
    </div>
    <div class="file-doc" *ngIf="!(file.mimeType?.includes('image') || file.mimeType?.includes('video'))"
         appNoContextMenu>
      <div class="icon">
        <img class="icon-file" src="{{file.name | iconForFile}}" alt="">
      </div>
      <div class="info">
        <div class="name">{{file.name}}</div>
        <div class="size">{{file.size | sizeFile}}</div>
      </div>
    </div>
    <div *ngIf="!file?.mimeType && !file?.name" class="file-image">
      <img src="assets/images/undelivered_file.svg" alt=""/>
    </div>
    <ion-icon *ngIf="withClosing" name="close-outline"
              (click)="$event.stopImmediatePropagation(); delete(i)"></ion-icon>
  </div>

  <div *ngIf="allowAdding" class="file" (click)="onAddFileButtonClick()">
    <div class="file-container">
    </div>
  </div>
</div>

<app-image-slider #imageSlider
                  [allowEditing]="allowEditing"
                  [allowDeleting]="allowEditing">
</app-image-slider>