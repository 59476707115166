import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TokenStoreService } from '../../auth/token-store.service';
import { AuthService, ParsedUser } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';


export const disputeCanActivate: CanActivateFn = async (route): Promise<boolean> => {
  const tokenStore = inject(TokenStoreService);
  const authService = inject(AuthService);
  const navController = inject(NavController);

  const token: string = await tokenStore.getToken();

  if (!token) return true;

  if (route.routeConfig?.path?.includes('/tasks/dispute/')) return true;

  const parsedUser: ParsedUser = authService.parsedUser;

  if (parsedUser?.replyOnResolveDispute?.hasReplyOnResolveDispute) {
    await navController.navigateBack('/tasks/dispute/' + parsedUser.replyOnResolveDispute.taskId + '/details', {replaceUrl: true});
    return false;
  }

  return true;
};