import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent implements OnInit {
  public replyId: number;
  public status: 'success' | 'fail';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.id && params.status) {
        if (+params.id) {
          this.replyId = +params.id;
          this.status = params.status;
        } else {
          this.router.navigate(['../']).then();
        }
      }
    });
  }

  async goToReply() {
    await this.router.navigate(['/reply/' + this.replyId], {replaceUrl: true});
  }
}
