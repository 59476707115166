import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ContextMenuContentComponent } from './context-menu-content.component';

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  popover: HTMLIonPopoverElement;
  @Input() icon: string;

  constructor(
    private popoverCtrl: PopoverController,
  ) {
  }

  async present(child, event) {
    this.popover = await this.popoverCtrl.create({
      component: ContextMenuContentComponent,
      componentProps: {
        template: child
      },
      event,
      translucent: true
    });
    return await this.popover?.present();
  }

}
