import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { NavController } from '@ionic/angular';
import { PlatformService } from '../services/platform.service';


export const mobileAppCanActivate: CanActivateFn = async (): Promise<boolean> => {
  const platformService = inject(PlatformService);
  const navController = inject(NavController);

  if (platformService.isDevice) {
    return true;
  } else {
    await navController.navigateForward('/avatar');
    return false;
  }
};