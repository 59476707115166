import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  PaymentIntentResult,
  Stripe,
  StripeElements,
  StripeElementsOptions,
  StripePaymentElement
} from '@stripe/stripe-js';
import { Client } from '../../api-clients/pyjam/client';
import { ToastService } from '../../services/toast.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
// import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
// import { loadScript } from "@paypal/paypal-js";
import { environment } from '../../../environments/environment';
import { LoadingController, LoadingOptions, NavController } from '@ionic/angular';
import { HttpStatusCode } from '@angular/common/http';
import { PAYMENT_INTENT_TYPE } from '../../reply/reply.constants';

declare var paypal: any;

class PaymentVm { // TODO: move to separate file
  clientSecret: string;
  amount: string;
  currency: string;
  id: string;
  gateway: string;
}

@Component({
  selector: 'app-payment-input',
  templateUrl: './payment-input.component.html',
  styleUrls: ['./payment-input.component.scss'],
})
export class PaymentInputComponent implements OnInit, OnDestroy {
  @ViewChild('paypalContainer') set paypalContainer(content: ElementRef) {
    if (content) {
      this.paypalContentSubject.next(content);
    }
  }

  @Input() public paymentIntentType: PAYMENT_INTENT_TYPE;
  @Input() public tipAmount: number;
  public cardErrors = null;
  public replyOrExtraWorkId: number;
  public pkCode = null;
  public vm: PaymentVm = new PaymentVm();
  public readonly environment = environment;
  public theme: string = '';
  public wasPay: boolean = false;
  private stripe: Stripe;
  private elements: StripeElements;
  private paymentElement: StripePaymentElement;
  private cardFields: any;
  private isDarkMode: boolean = true;
  private loadingElement: HTMLIonLoadingElement;
  private paypalContentSubject: BehaviorSubject<ElementRef | null> = new BehaviorSubject<ElementRef | null>(null);
  private paymentGatewaySubject: BehaviorSubject<string> = new BehaviorSubject<string | null>(null);
  public cardFormIsComplete: boolean = false;

  constructor(
    private client: Client,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private navController: NavController,
  ) {
  }

  ngOnDestroy() {
    try {
      if (this.paymentElement) {
        this.paymentElement.destroy();
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (params: Params): Promise<void> => {
      if (params && params.id) {
        if (+params.id) {
          this.replyOrExtraWorkId = +params.id;
          await this.loadingService.start();

          combineLatest([this.paypalContentSubject, this.paymentGatewaySubject])
            .subscribe(async ([paypalContent, gateway]: [ElementRef, string]): Promise<void> => {
              if (paypalContent && gateway === 'paypal') {
                await this.addPaypalSdk();
                await this.createPaypalButtons();
                this.createPaypalForm();
              }
            });

          await this.loadSecretCode();

          if ((typeof window !== 'undefined') && window.matchMedia) {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
            this.isDarkMode = prefersDarkMode.matches;

            prefersDarkMode.addEventListener('change', (event): void => {
              this.isDarkMode = event.matches;
            });
          }

          await this.loadingService.stop();
        } else {
          await this.router.navigate(['../']);
          return;
        }
      }
    });
  }

  private async createStripeForm(): Promise<void> {
    try {
      await this.loadingService.start();

      this.stripe = await loadStripe(this.pkCode);
      let stripeElementsOptions: StripeElementsOptions = {
        clientSecret: this.vm.clientSecret,
        appearance: {
          theme: 'night',
        },
        locale: 'en'
      };
      this.elements = this.stripe.elements(stripeElementsOptions);
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount('#card-element');

      this.paymentElement.on('change', (event) => {
        this.cardFormIsComplete = event.complete;
      });
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  private async loadSecretCode(): Promise<any> {
    try {
      await this.loadingService.start();

      const data = await firstValueFrom(this.client.paymentIntent(
        this.replyOrExtraWorkId,
        this.paymentIntentType,
        this.tipAmount ? this.tipAmount : undefined,
        undefined,
        undefined
      ));
      console.log(data);

      let payment: PaymentVm = new PaymentVm();

      switch (data.gateway) {
        case 'stripe':
          payment.amount = data.amount.toString();
          payment.clientSecret = data.client_secret;
          payment.currency = data.currency;
          payment.gateway = data.gateway;
          this.vm = payment;
          break;
        case 'paypal':
          payment.id = data.id;
          payment.gateway = data.gateway;
          payment.amount = data.amount.toString();
          payment.currency = data.currency;
          this.vm = payment;
          break;
        default:
          await this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
          break;
      }

      this.paymentGatewaySubject.next(payment.gateway);

    } catch (error) {
      let parsedException = JSON.parse(error.response);
      let message = parsedException.message ?? parsedException.error;
      await this.toastService.error(message);
    } finally {
      await this.loadingService.stop();
    }
  }

  public async sendCardToken(): Promise<void> {
    switch (this.vm.gateway) {
      case 'stripe':
        try {
          await this.loadingService.start();

          const result: PaymentIntentResult = await this.stripe.confirmPayment({
            elements: this.elements,
            redirect: 'if_required'
          });

          if (result.error) {
            this.cardErrors = result.error.message;
          } else {
            switch (this.paymentIntentType) {
              case PAYMENT_INTENT_TYPE.REPLY:
                await this.approveReply();
                break;
              case PAYMENT_INTENT_TYPE.REPLY_TIPS:
                try {
                  await this.loadingService.start();

                  await firstValueFrom(this.client.replyGet(this.replyOrExtraWorkId, false));
                  await this.navigationService.goBack();
                } catch (error) {
                  await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                } finally {
                  await this.loadingService.stop();
                }
                break;
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          await this.loadingService.stop();
        }
        break;
      case 'paypal':
        try {
          await this.loadingService.start();

          this.cardFields.submit();
          console.log('Card Fields submit');
        } catch (error) {
          this.cardErrors = error;

          console.log('There was an error with card fields: ', error);
        } finally {
          await this.loadingService.stop();
        }
        break;
      default:
        await this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
        break;
    }
  }

  private async approveReply(): Promise<void> {
    try {
      await this.loadingService.start();

      await firstValueFrom(this.client.replyApprove(+this.replyOrExtraWorkId));
      await this.toastService.success(this.translate.instant('reply.payment.successPay'));
      await this.navigationService.goBack();
    } catch (error) {
      let parsedException = JSON.parse(error.response);
      let message = parsedException.message ?? parsedException.error;
      await this.toastService.error(message);
      await this.router.navigate(
        ['../payment-result', 'fail'],
        {relativeTo: this.activatedRoute, replaceUrl: true}
      );
    } finally {
      await this.loadingService.stop();
    }
  }

  public async selectPaymentMethod($event: any): Promise<void> {
    this.pkCode = $event.detail.value;
    await this.createStripeForm();
  }

  private async createPaypalButtons(): Promise<void> {
    try {
      await this.loadingService.start();

      const styleButtons = {
        'layout': 'vertical',
        'color': 'blue',
        'shape': 'rect',
        'label': 'pay',
      };
      const paypalButtons = await paypal.Buttons({
        style: styleButtons,
        onClick: async (): Promise<void> => {
          if (this.isDarkMode) {
            setTimeout((): void => {
              this.theme = 'dark'; // this change header theme to light:)
            }, 500);
          }
        },
        createOrder: async (): Promise<unknown> => {
          return new Promise((resolve) => resolve(this.vm.id));
        },
        onApprove: async (data: any): Promise<void> => {
          try {
            await this.startPaymentLoader();

            switch (this.paymentIntentType) {
              case PAYMENT_INTENT_TYPE.REPLY:
                try {
                  const res = await firstValueFrom(this.client.paypalAuthorizeOrder(data.orderID));
                  console.log(res);
                  await this.stopPaymentLoader();
                  await this.approveReply();
                } catch (error) {
                  if (error?.status === HttpStatusCode.UnprocessableEntity) {
                    const responseMessage: string = error?.result?.error ?? error?.result?.message;
                    await this.toastService.error(this.translate.instant(responseMessage)); // 'payment.get_status_failed'
                  }
                  this.cardErrors = error.result.message;
                }
                break;
              case PAYMENT_INTENT_TYPE.EXTRA_WORK:
              case PAYMENT_INTENT_TYPE.AVATAR_ORDER:
                try {
                  const res = await firstValueFrom(this.client.paypalAuthorizeOrder(data.orderID));
                  console.log(res);
                } catch (error) {
                  if (error?.status === HttpStatusCode.UnprocessableEntity) {
                    const responseMessage: string = error?.result?.error ?? error?.result?.message;
                    await this.toastService.error(this.translate.instant(responseMessage)); // 'payment.get_status_failed'
                  }
                  this.cardErrors = error.result.message;
                }
                break;
              case PAYMENT_INTENT_TYPE.REPLY_TIPS:
                try {
                  const res = await firstValueFrom(this.client.paypalCaptureOrder(data.orderID));
                  console.log(res);

                  try {
                    await firstValueFrom(this.client.replyGet(this.replyOrExtraWorkId, false));
                    await this.navigationService.goBack();
                  } catch (err) {
                    await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                  }

                } catch (error) {
                  this.cardErrors = error.result.message;
                }
                break;
              case PAYMENT_INTENT_TYPE.AVATAR_ORDER_TIPS:
                try {
                  await firstValueFrom(this.client.paypalCaptureOrder(data.orderID));
                  console.log(data);
                  // TODO: make logic for avatar tips
                } catch (error) {
                  this.cardErrors = error.result.message;
                }
                break;
              default:
                console.warn('default');
                break;
            }
          } catch (error) {
            this.cardErrors = error.result.message;
          } finally {
            if (this.isDarkMode) {
              this.theme = ''; // this change to dark
            }
            await this.stopPaymentLoader();
          }
        },
        onCancel: async (): Promise<void> => {
          if (this.isDarkMode) {
            this.theme = ''; // this change to dark
          }
          await this.stopPaymentLoader();
          await this.navController.navigateBack(['/avatar'], {replaceUrl: true});
        },
        onError: async (): Promise<void> => {
          await this.stopPaymentLoader();
          this.cardErrors = 'The requested action could not be performed, semantically incorrect, or failed business validation';
        }
      });
      paypalButtons.render('#paypal-button-container');
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  private createPaypalForm(): void {
    const style = {
      'input': {
        'font-size': '16px',
        'padding': '12px',
        'line-height': '18px',
      },
    };

    this.cardFields = paypal.CardFields({
      style,
      createOrder: async (): Promise<string> => {
        return new Promise((resolve) => resolve(this.vm.id));
      },
      onApprove: async (data: any): Promise<void> => {
        await this.startPaymentLoader();

        switch (this.paymentIntentType) {
          case PAYMENT_INTENT_TYPE.REPLY:
            try {
              const res = await firstValueFrom(this.client.paypalAuthorizeOrder(data.orderID));
              console.log(res);
              await this.approveReply();
            } catch (error) {
              if (error?.status === HttpStatusCode.UnprocessableEntity) {
                const responseMessage: string = error?.result?.error ?? error?.result?.message;
                await this.toastService.error(this.translate.instant(responseMessage)); // 'payment.get_status_failed'
              }
              this.cardErrors = error.result.message;
            } finally {
              await this.stopPaymentLoader();
            }
            break;
          case PAYMENT_INTENT_TYPE.EXTRA_WORK:
          case PAYMENT_INTENT_TYPE.AVATAR_ORDER:
            try {
              const res = await firstValueFrom(this.client.paypalAuthorizeOrder(data.orderID));
              console.log(res);
            } catch (error) {
              if (error?.status === HttpStatusCode.UnprocessableEntity) {
                const responseMessage: string = error?.result?.error ?? error?.result?.message;
                await this.toastService.error(this.translate.instant(responseMessage)); // 'payment.get_status_failed'
              }
              this.cardErrors = error.result.message;
            } finally {
              await this.stopPaymentLoader();
            }
            break;
          case PAYMENT_INTENT_TYPE.REPLY_TIPS:
            try {
              const res = await firstValueFrom(this.client.paypalCaptureOrder(data.orderID));
              console.log(res);

              try {
                await firstValueFrom(this.client.replyGet(this.replyOrExtraWorkId, false));
                await this.navigationService.goBack();
              } catch (err) {
                await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
              } finally {
                await this.stopPaymentLoader();
              }

            } catch (error) {
              this.cardErrors = error.result.message;
            }
            break;
          case PAYMENT_INTENT_TYPE.AVATAR_ORDER_TIPS:
            try {
              const res = await firstValueFrom(this.client.paypalCaptureOrder(data.orderID));
              console.log(res);
              // TODO: make logic for avatar tips
            } catch (error) {
              this.cardErrors = error.result.message;
            } finally {
              await this.stopPaymentLoader();
            }
            break;
          default:
            console.warn('default');
            break;
        }
      },
      onError: async (): Promise<void> => {
        await this.stopPaymentLoader();
        this.cardErrors = 'The requested action could not be performed, semantically incorrect, or failed business validation';
      },
      inputEvents: {
        onChange: (event) => {
          this.cardFormIsComplete = event.isFormValid;
        }
      }
    });

    // Render each field after checking for eligibility
    if (this.cardFields.isEligible()) {
      const nameField = this.cardFields.NameField();
      nameField.render('#card-name-field-container');

      const numberField = this.cardFields.NumberField();
      numberField.render('#card-number-field-container');

      const cvvField = this.cardFields.CVVField();
      cvvField.render('#card-cvv-field-container');

      const expiryField = this.cardFields.ExpiryField();
      expiryField.render('#card-expiry-field-container');
    }

    // const multiCardFieldButton = document.getElementById("card-field-submit-button")

    // multiCardFieldButton.addEventListener("click", () => {
    //   console.log("multiCardFieldButton clicked");

    //   cardFields.submit().then(() => {
    //     console.log("Card Fields submit");
    //   }).catch((error) => {
    //     console.log("There was an error with card fields: ", error);
    //   });
    // });

    // this.cardFields = cardFields;

    // console.log(cardFields);

    // console.log(loadScript);

    // loadScript({
    //   clientId: environment.paypal.clientId,
    //   components: ['buttons']
    // }).then((paypal) => {

    //     paypal
    //       .Buttons({
    //         onApprove: (data, actions) => new Promise(() => {
    //           console.log(data, actions);
    //         }),
    //         createOrder: () => new Promise(() => {
    //           console.log(this.vm.id);
    //           return this.vm.id;
    //         }),
    //         onError(error) {
    //           console.log(error);
    //         },
    //       })
    //       .render("#paypal-button-container")
    //       .catch((error) => {
    //         console.error("failed to render the PayPal Buttons", error);
    //       });

    //     // if (false) {
    //       paypal.HostedFields.render({
    //         createOrder: () => new Promise(() => this.vm.id),
    //         // function () {
    //         //   return fetch('/your-server/paypal/order', {
    //         //     method: 'post'
    //         //   }).then(function(res) {
    //         //     return res.json();
    //         //   }).then(function(orderData) {
    //         //     orderId = orderData.id;
    //         //     return orderId;
    //         //   });
    //         // },
    //         styles: {
    //           '.valid': {
    //             'color': 'green'
    //           },
    //           '.invalid': {
    //             'color': 'red'
    //           }
    //         },
    //         fields: {
    //           number: {
    //             selector: "#card-number-field-container",
    //             placeholder: "4111 1111 1111 1111"
    //           },
    //           cvv: {
    //             selector: "#card-cvv-field-container",
    //             placeholder: "123"
    //           },
    //           expirationDate: {
    //             selector: "#card-expiry-field-container",
    //             placeholder: "MM/YY"
    //           }
    //         }
    //       }).then(function (cardFields) {

    //         console.log(cardFields);


    //         // document.querySelector("#card-form").addEventListener('submit', (event) => {
    //         //   event.preventDefault();
    //         //   cardFields.submit({
    //         //     cardholderName: document.getElementById('card-holder-name').value,
    //         //     billingAddress: {
    //         //       streetAddress: document.getElementById('card-billing-address-street').value,
    //         //       extendedAddress: document.getElementById('card-billing-address-unit').value,
    //         //       region: document.getElementById('card-billing-address-state').value,
    //         //       locality: document.getElementById('card-billing-address-city').value,
    //         //       postalCode: document.getElementById('card-billing-address-zip').value,
    //         //       countryCodeAlpha2: document.getElementById('card-billing-address-country').value
    //         //     }
    //         //   }).then(function () {
    //         //     fetch('/your-server/api/order/' + orderId + '/capture/', {
    //         //       method: 'post'
    //         //     }).then(function(res) {
    //         //       return res.json();
    //         //     }).then(function (orderData) {
    //         //       var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
    //         //       if (errorDetail) {
    //         //         var msg = 'Sorry, your transaction could not be processed.';
    //         //         if (errorDetail.description) msg += '\n' + errorDetail.description;
    //         //         if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
    //         //         return alert(msg); // Show a failure message
    //         //       }
    //         //       alert('Transaction completed!');
    //         //     })
    //         //   }).catch(function (error) {
    //         //     alert('Payment could not be captured! ' + JSON.stringify(error))
    //         //   });
    //         // });
    //       });
    //     // } else {
    //       // document.querySelector("#card-form").style = 'display: none';
    //     // }
    // })
    // .catch((error) => {
    //   console.error("failed to load the PayPal JS SDK script", error);
    // });
  }

  private async startPaymentLoader(): Promise<void> {
    if (this.loadingElement) return;

    const options: LoadingOptions = {
      message: this.translate.instant('avatar.orderPay.awaitPayment'),
      backdropDismiss: false,
    };
    this.loadingElement = await this.loadingController.create(options);
    await this.loadingElement?.present();
  }

  private async stopPaymentLoader(): Promise<void> {
    await this.loadingElement?.dismiss();
  }

  async addPaypalSdk(): Promise<void> {
    let paypalClientId: string = environment.paypal.clientId;

    if (!paypalClientId) return;
    if (typeof document === 'undefined') return;

    let intent: string = 'authorize';

    switch (this.paymentIntentType) {
      case PAYMENT_INTENT_TYPE.REPLY_TIPS:
      case PAYMENT_INTENT_TYPE.AVATAR_ORDER_TIPS:
        intent = 'capture';
        break;
    }

    return new Promise<void>(async (resolve, reject): Promise<void> => {
      await this.loadingService.start();

      const scriptElement: HTMLScriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.onload = async (): Promise<void> => {
        console.log('Paypal js sdk loaded');
        await this.loadingService.stop();
        resolve();
      };
      scriptElement.onerror = async (error: string | Event): Promise<void> => {
        console.log('Error load paypal js sdk:', error);
        await this.loadingService.stop();
        reject(error);
      };
      scriptElement.src = `https://www.paypal.com/sdk/js?intent=${intent}&client-id=${paypalClientId}&debug=true&currency=EUR&components=buttons,card-fields`;
      document.getElementsByTagName('head')[0].insertAdjacentElement('afterbegin', scriptElement);
    });
  }
}
