import { Injectable } from '@angular/core';
import { ReportContentPopupComponent } from '../ui-components/report-content-popup/report-content-popup.component';
import { ReportType } from '../report/report.constants';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private modalController: ModalController,
  ) {
  }

  public async openReportModal(reportType: ReportType, reportedObjectId: number): Promise<void> {
    const reportModal: HTMLIonModalElement = await this.modalController.create({
      component: ReportContentPopupComponent,
      componentProps: {
        reportType: reportType,
        reportedObjectId: reportedObjectId,
      },
    });

    await reportModal?.present();
  }

}
