import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Client, OnboardingCountyCodes } from '../../../api-clients/pyjam/client';
import { IonInput, IonModal } from '@ionic/angular';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneInputComponent
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(IonInput) phoneInput: IonInput;

  codes: OnboardingCountyCodes[];
  phone = new PhoneInputControlValue();
  onChange = (phone) => {
  };
  onTouched = () => {
  };

  touched = false;

  constructor(
    private loadingService: LoadingService,
    private client: Client,
  ) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const phoneInput = control.value as PhoneInputControlValue;
    if (!phoneInput.isPhoneValid) {
      return {
        phoneLength: phoneInput.code?.phone_length
      };
    }
  }

  ngOnInit() {
    this.loadingService.start()
      .then(async () => {
        await this.client.authGetCountryPhoneCodes()
          .toPromise()
          .then((codes) => {
            this.codes = codes;
            this.phone.code = codes.find((code) => code.default);
          })
          .finally(async () => {
            await this.loadingService.stop();
          });
      });
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(phone: any): void {
    this.phone.phone = phone;
  }

  setDisabledState(isDisabled: boolean) {
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  async phoneCodeChanged($event: any) {
    this.phone.code = $event.detail.value;
    this.phoneInput.value = '';
    await this.modal.dismiss();
  }

  public async onInputKeyDown($event): Promise<void> {
    if ($event.key == 'ArrowRight' || $event.key == 'ArrowDown'
      || $event.key == 'ArrowLeft' || $event.key == 'ArrowUp'
      || $event.key == 'Delete' || $event.key == 'Backspace'
      || $event.key == '0' || $event.key == '1'
      || $event.key == '2' || $event.key == '3'
      || $event.key == '4' || $event.key == '5'
      || $event.key == '6' || $event.key == '7'
      || $event.key == '8' || $event.key == '9') {
    } else {
      $event.preventDefault();
    }
  }

  public async onInputPaste(event): Promise<void> {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.phone.phone = pastedText.replace(/[^\d]/g, '');
    event.preventDefault();
  }

  onInputFocus() {
    this.markAsTouched();
  }

  onInputValueChange($event) {
    this.phone.phone = $event.detail.value;
    this.onChange(this.phone);
  }
}

export class PhoneInputControlValue {
  code: OnboardingCountyCodes;
  phone: string;

  get isPhoneValid(): boolean {
    return this.code ? new RegExp('^[0-9]{' + this.code.min_digits + ',' + this.code.max_digits + '}$').test(this.phone) : false;
  }

  toString() {
    return this.code && this.phone
      ? `${this.code.phone_code}${this.phone}`
      : '';
  }
}
