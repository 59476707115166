<ion-content [fullscreen]="true">
  <ion-progress-bar *ngIf="progress" [value]="progress" [ngClass]="overlays ? 'withOverlay' : 'withoutOverlay'"></ion-progress-bar>

  <ion-button *ngIf="!progress" class="close-btn" [ngClass]="overlays ? 'withOverlay' : 'withoutOverlay'" color="clear" (click)="close('close')">
    <img src="assets/icon/close.svg" alt="close">
  </ion-button>

  <div class="info">
    <ion-icon name="cloud-download-outline"></ion-icon>
    <h4>{{ 'updateAppModal.message' | translate }}</h4>
    <ion-button class="download-btn" (click)="downloadApp()" color="light">{{ 'updateAppModal.button' | translate }}</ion-button>
  </div>
</ion-content>