import { Injectable, } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  delay,
  exhaustMap,
  filter,
  first,
  from,
  Observable,
  of,
  skip,
  Subject,
  tap
} from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { TokenStoreService } from '../auth/token-store.service';
import { AuthService, ParsedToken, ParsedUser } from '../auth/auth.service';
import { AppStateChangeService } from './app-state-change.service';
import {
  EmitMessage,
  EmitRead,
  EventAnswer,
  EventAvatarCustomerReady,
  EventAvatarCustomerReadyReconnect,
  EventAvatarRequestReconnect,
  EventAvatarResponse,
  EventAvatarStatusUpdated,
  EventAvatarStreamerReady,
  EventAvatarSyncStatus,
  EventAvatarViewerReady,
  EventChatCreated,
  EventFileUploaded,
  EventRead,
  PhoneInfoRequest,
  PhoneInfoRequestExt,
  PhoneInfoResponse,
  PhoneInfoResponseExt,
  SocketError
} from './web-socket.interfaces';
import { distinctUntilChanged } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { SubscriptionsBag } from './subscriptions-bag';
import { OnlineStateService } from './online-state.service';
import { JwtService } from '../auth/jwt.service';
import { AvatarOrderResponse } from '../api-clients/pyjam/client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketController {
  public chatEvent: Subject<any> = new Subject();
  public notificationEvent: Subject<any> = new Subject();
  public avatarEvent: Subject<any> = new Subject();
  public fileUploadEvent: Subject<any> = new Subject();
  public isSocketInitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private errSubject: Subject<SocketError> = new Subject();
  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private socket: Socket,
    private tokenStore: TokenStoreService,
    private authService: AuthService,
    private appStateChangeService: AppStateChangeService,
    private platformService: PlatformService,
    private onlineStateService: OnlineStateService,
    private jwtService: JwtService,
  ) {
  }

  ngOnDestroy() {
    this.sb.unsubscribeAll();
    this.errSubject?.unsubscribe();
  }

  public get socketId(): string {
    return this.socket.ioSocket.id;
  }

  public get socketConnected(): boolean {
    return this.socket.ioSocket.connected;
  }

  public async init(): Promise<void> {
    if (this.onlineStateService.isOffline) {
      this.sb.sub = this.onlineStateService.isOffline$.pipe(
        first((isOffline: boolean): boolean => !isOffline),
      ).subscribe(async (): Promise<void> => {
        await this.init();
      });
      return;
    }

    this.subscribeToSocketEvents();

    this.sb.sub = this.authService.parsedUser$.pipe(
      distinctUntilChanged(),
      tap((parsedUser: ParsedUser): void => {
        if (parsedUser) {
          if (!this.socketConnected) this.socket.connect();
        } else {
          if (this.socketConnected) this.socket.disconnect();
        }
      })
    ).subscribe();

    this.sb.sub = this.authService.parsedToken$.pipe(
      distinctUntilChanged(),
      filter((parsedToken: ParsedToken): boolean => !!parsedToken),
      skip(1),
      tap(async (): Promise<void> => {
        await this.initJwtToken();
      })
    ).subscribe();

    this.sb.sub = this.appStateChangeService.appStateChange$.pipe(
      filter((): boolean => this.platformService.isDevice),
      filter((isActive: boolean): boolean => isActive),
      skip(1),
      tap((): void => {
        this.socketConnect();
      })
    ).subscribe();
  }

  //#region Socket Emits

  public emitAnySocketEvent(eventName: string, eventData: any): void {
    this.validateEmitData({...eventData}, eventName);
    // console.log('\x1b[35m' + `Socket emit '${eventName}'` + '\x1b[0m', eventData);
    this.socket.emit(eventName, eventData);
  }

  public emitInit(jwtToken: string): void {
    this.validateEmitData({jwtToken}, 'init');
    // console.log('\x1b[35m' + `Socket emit 'init'` + '\x1b[0m', jwtToken);
    this.socket.emit('init', jwtToken);
  }

  public emitMessage(data: EmitMessage): void {
    this.validateEmitData(data, 'message');
    // console.log('\x1b[35m' + `Socket emit 'message'` + '\x1b[0m', data);
    this.socket.emit('message', data);
  }

  public emitGetHistory(message_id: string, to_id: number, count: number, chat_id: number, skip: number = 0): void {
    const data = {
      message_id,
      to_id,
      count,
      chat_id,
      skip
    };
    this.validateEmitData(data, 'getHistory');
    // console.log('\x1b[35m' + `Socket emit 'getHistory'` + '\x1b[0m', data);
    this.socket.emit('getHistory', data);
  }

  public emitRead(data: EmitRead): void {
    this.validateEmitData(data, 'read');
    // console.log('\x1b[35m' + `Socket emit 'read'` + '\x1b[0m', data); read: ', data);
    this.socket.emit('read', data);
  }

  public emitAvatarCustomerReady(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarCustomerReady');
    // console.log('\x1b[35m' + `Socket emit 'avatarCustomerReady'` + '\x1b[0m', data);
    this.socket.emit('avatarCustomerReady', data);
  }

  public emitAvatarMessage(user_id: number, command: string): void {
    const data = {user_id, message: command};
    this.validateEmitData(data, 'avatarMessage');
    // console.log('\x1b[35m' + `Socket emit 'avatarMessage'` + '\x1b[0m', data);
    this.socket.emit('avatarMessage', data);
  }

  public emitAvatarOffer(customer_id: number, order_id: number, offer: RTCSessionDescriptionInit): void {
    const data = {user_id: customer_id, order_id, offer};
    this.validateEmitData(data, 'avatarOffer');
    // console.log('\x1b[35m' + `Socket emit 'avatarOffer'` + '\x1b[0m', data);
    this.socket.emit('avatarOffer', data);
  }

  public emitAvatarAnswer(executor_id: number, answer: RTCSessionDescriptionInit): void {
    const data = {user_id: executor_id, answer};
    this.validateEmitData(data, 'avatarAnswer');
    // console.log('\x1b[35m' + `Socket emit 'avatarAnswer'` + '\x1b[0m', data);
    this.socket.emit('avatarAnswer', data);
  }

  public emitAvatarCandidate(user_id: number, candidate: RTCIceCandidateInit): void {
    const data = {user_id, candidate};
    this.validateEmitData(data, 'avatarCandidate');
    // console.log('\x1b[35m' + `Socket emit 'avatarCandidate'` + '\x1b[0m', data);
    this.socket.emit('avatarCandidate', data);
  }

  public emitAvatarExit(user_id: number): void {
    const data = {user_id};
    this.validateEmitData(data, 'avatarExit');
    // console.log('\x1b[35m' + `Socket emit 'avatarExit'` + '\x1b[0m', data);
    this.socket.emit('avatarExit', data);
  }

  public emitAvatarPhoneInfoRequest(to_id: number): void {
    const data: PhoneInfoRequest = {to_id};
    this.validateEmitData(data, 'avatarPhoneInfoRequest');
    // console.log('\x1b[35m' + `Socket emit 'avatarPhoneInfoRequest'` + '\x1b[0m', data);
    this.socket.emit('avatarPhoneInfoRequest', data);
  }

  public emitAvatarPhoneInfoResponse(to_id: number, battery_level: number, is_charging: boolean, phone_model: string): void {
    const data: PhoneInfoResponse = {to_id, battery_level, is_charging, phone_model};
    this.validateEmitData(data, 'avatarPhoneInfoResponse');
    // console.log('\x1b[35m' + `Socket emit 'avatarPhoneInfoResponse'` + '\x1b[0m', data);
    this.socket.emit('avatarPhoneInfoResponse', data);
  }

  public emitAvatarSyncStatus(is_online: boolean): void {
    const data = {is_online};
    this.validateEmitData(data, 'avatarSyncStatus');
    // console.log('\x1b[35m' + `Socket emit 'avatarSyncStatus'` + '\x1b[0m', data);
    this.socket.emit('avatarSyncStatus', data);
  }

  public emitAvatarRequestReconnect(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarRequestReconnect');
    // console.log('\x1b[35m' + `Socket emit 'avatarRequestReconnect'` + '\x1b[0m', data);
    this.socket.emit('avatarRequestReconnect', data);
  }

  public emitAvatarCustomerReadyReconnect(to_id: number, ready_reconnect: boolean, order_id: number): void {
    const data = {to_id, ready_reconnect, order_id};
    this.validateEmitData(data, 'avatarCustomerReadyReconnect');
    // console.log('\x1b[35m' + `Socket emit 'avatarCustomerReadyReconnect'` + '\x1b[0m', data);
    this.socket.emit('avatarCustomerReadyReconnect', data);
  }

  public emitAvatarZoom(to_id: number, zoom: number): void {
    const data = {to_id, zoom};
    this.validateEmitData(data, 'avatarZoom');
    this.socket.emit('avatarZoom', data);
  }

  public emitAvatarStats(to_id: number, bitrate: string): void {
    const data = {to_id, bitrate};
    this.validateEmitData(data, 'avatarStats');
    this.socket.emit('avatarStats', data);
  }

  public emitEventForDev(to_id: number, data: any): void { // developer-only
    this.validateEmitData(data, 'eventForDev');
    this.socket.emit('eventForDev', {to_id, data});
  }

  public emitAvatarSaveData(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarSaveData');
    console.log('\x1b[35m' + `Socket emit 'avatarSaveData'` + '\x1b[0m', data);
    this.socket.emit('avatarSaveData', data);
  }

  public emitAvatarViewerReady(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarViewerReady');
    console.log('\x1b[35m' + `Socket emit 'avatarViewerReady'` + '\x1b[0m', data);
    this.socket.emit('avatarViewerReady', data);
  }

  public emitAvatarStreamerReady(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarStreamerReady');
    console.log('\x1b[35m' + `Socket emit 'avatarStreamerReady'` + '\x1b[0m', data);
    this.socket.emit('avatarStreamerReady', data);
  }

  //#endregion Socket Emits

  private async initJwtToken(): Promise<void> {
    if (this.onlineStateService.isOffline) return;
    if (!this.socketConnected) return;

    if (!this.authService.isAuthenticated) {
      const message: string = 'Method initJwtToken: User is not authenticated!';
      this.emitEventForDev(this.authService.parsedToken.userId, {message});
      return console.warn(message);
    }

    const jwtToken: string = await this.tokenStore.getToken();

    if (!jwtToken) {
      const message: string = 'initJwtToken: Token is not found!';
      this.emitEventForDev(this.authService.parsedToken.userId, {message});
      return console.error(message);
    }

    const isTokenExpired: boolean = this.jwtService.checkIsTokenExpired(jwtToken);
    if (isTokenExpired) {
      const message: string = 'initJwtToken: Token is expired!';
      this.emitEventForDev(this.authService.parsedToken.userId, {message});
      console.warn(message);
      return await this.authService.refreshToken(); // initJwtToken will repeat after refreshToken on parsedToken$ subscription
    }

    this.emitInit(jwtToken);
  }

  private async refreshToken(): Promise<void> {
    return this.authService.refreshToken();
  }

  public socketConnect(): void {
    if (this.onlineStateService.isOffline) return;
    if (this.socketConnected) return;
    if (!this.authService.isAuthenticated) return;

    this.socket.connect();
  }

  private subscribeToSocketEvents(): void {
    this.errSubject.pipe(
      filter((error: SocketError): boolean => error?.message === 'Unauthenticated.' || error?.message === 'invalid credentials'),
      exhaustMap((): Observable<any> => from(this.refreshToken()).pipe(
        catchError(error => {
          return of(error);
        })
      )),
      delay(1500),
      tap((error: SocketError): void => {
        if (error?.lastMethod !== 'init' && error?.lastData) {
          this.emitAnySocketEvent(error.lastMethod, error.lastData);
        }
      })
    ).subscribe();

    //logEvents

    this.socket.on('err', (data: SocketError): void => {
      this.errSubject.next(data);
      console.error('Socket err event! ', data);
      // if (data && data.message && data.message != 'avatar_order.broadcast_is_started') {
      //   this.toastService.error(data.message);
      // }
    });

    this.socket.on('log', (data: SocketError): void => {
      console.warn('Socket log event! ', data);
    });

    //connectEvents

    this.socket.on('connect', async (): Promise<void> => {
      console.info('\x1b[32m' + 'Socket is connected' + '\x1b[0m');
      if (!environment.production) console.info('\x1b[33m' + 'Socket ID: ' + '\x1b[0m' + this.socketId);
      await this.initJwtToken();
    });

    this.socket.on('disconnect', (reason: any): void => {
      console.info('\x1b[31m' + 'Socket is disconnected!' + '\x1b[0m' + ' Reason: ' + reason);
      this.isSocketInitedSubject.next(false);

      if (reason === 'io server disconnect') {
        this.socketConnect();
      }
    });

    this.socket.on('connect_error', (error: any): void => {
      console.error('Socket connection error:', error.message);
      if (this.onlineStateService.isOffline) {
        this.socket.disconnect();

        this.sb.sub = this.onlineStateService.isOffline$.pipe(
          first((isOffline: boolean): boolean => !isOffline),
        ).subscribe(async (): Promise<void> => {
          this.socketConnect();
        });
        return;
      }

      setTimeout((): void => {
        this.socketConnect();
      }, 3_000);
    });

    this.socket.on('answer', (data: EventAnswer): void => {
      if (data.type === 'init' && data.response) {
        console.log('\x1b[35m' + `Socket event 'answer'` + '\x1b[0m', data.message);
        this.isSocketInitedSubject.next(true);
      }

      if (data.type === 'init' && !data.response) {
        this.isSocketInitedSubject.next(false);
        console.error(`Socket event 'answer', error:`, data.message, data?.error);
      }
    });

    //chatEvents

    this.socket.on('message', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'message'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'message');
      this.chatEvent.next({
        type: 'message',
        data
      });
    });

    this.socket.on('replyStatusChanged', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'replyStatusChanged'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'replyStatusChanged');
      this.chatEvent.next({
        type: 'replyStatusChanged',
        data
      });
    });

    this.socket.on('taskCreated', (data: number): void => {
      // console.log('\x1b[35m' + `Socket event 'taskCreated'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'taskCreated');
      this.chatEvent.next({
        type: 'taskCreated',
        data
      });
    });

    this.socket.on('qtyUnreadMessages', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'qtyUnreadMessages'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'qtyUnreadMessages');
      this.chatEvent.next({
        type: 'qtyUnreadMessages',
        data
      });
    });

    this.socket.on('chatCreated', (data: EventChatCreated): void => {
      // console.log('\x1b[35m' + `Socket event 'chatCreated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'chatCreated');
      this.chatEvent.next({
        type: 'chatCreated',
        data
      });
    });

    this.socket.on('dataUpdated', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'dataUpdated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'dataUpdated');
      this.chatEvent.next({
        type: 'dataUpdated',
        data
      });
    });

    this.socket.on('getHistory', (docs: any): void => {
      // console.log('\x1b[35m' + `Socket event 'getHistory'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(docs, 'getHistory');
      this.chatEvent.next({
        type: 'getHistory',
        messages: docs
      });
    });

    this.socket.on('delivered', (guid: number): void => {
      // console.log('\x1b[35m' + `Socket event 'delivered'` + '\x1b[0m', data);
      this.validateReceivedData(guid, 'delivered');
      this.chatEvent.next({
        type: 'message_delivered',
        data: guid
      });
    });

    this.socket.on('undelivered', (guid: number): void => {
      // console.log('\x1b[35m' + `Socket event 'undelivered'` + '\x1b[0m', data);
      this.validateReceivedData(guid, 'undelivered');
      this.chatEvent.next({
        type: 'undelivered',
        data: guid
      });
    });

    this.socket.on('read', (data: EventRead): void => {
      // console.log('\x1b[35m' + `Socket event 'read'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'read');
      this.chatEvent.next({
        type: 'read',
        data
      });
    });

    // this.socket.on('fileNotReady', (data: any): void => {
    //   // console.log('\x1b[35m' + `Socket event 'fileNotReady'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
    //   this.validateReceivedData(data, 'fileNotReady');
    //   this.chatEvent.next({
    //     type: 'fileNotReady',
    //     data
    //   });
    // });

    //notificationEvent

    this.socket.on('notification', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'notification' from backend` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      // this.validateReceivedData(data, 'notification');
      this.notificationEvent.next({
        type: 'notification',
        data
      });
    });

    //fileUploadEvent

    this.socket.on('fileUploaded', (data: EventFileUploaded): void => {
      console.log('\x1b[35m' + `Socket event 'fileUploaded'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'fileUploaded');
      this.fileUploadEvent.next({
        type: 'fileUploaded',
        data
      });
    });

    //avatarEvents

    this.socket.on('avatarSyncStatus', (data: EventAvatarSyncStatus): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarSyncStatus'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarSyncStatus');
      this.avatarEvent.next({
        type: 'syncStatus',
        data
      });
    });

    this.socket.on('avatarStatusUpdated', (data: EventAvatarStatusUpdated): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarStatusUpdated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarStatusUpdated');
      this.avatarEvent.next({
        type: 'status',
        data
      });
    });

    this.socket.on('avatarPhoneInfoRequest', (info: PhoneInfoRequestExt): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarPhoneInfoRequest'` + '\x1b[0m', info);
      this.validateReceivedData(info, 'avatarPhoneInfoRequest');
      this.avatarEvent.next({
        type: 'phoneInfoRequest',
        info
      });
    });

    this.socket.on('avatarPhoneInfoResponse', (info: PhoneInfoResponseExt): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarPhoneInfoResponse'` + '\x1b[0m', info);
      this.validateReceivedData(info, 'avatarPhoneInfoResponse');
      this.avatarEvent.next({
        type: 'phoneInfoResponse',
        info
      });
    });

    this.socket.on('avatarRequest', (order: AvatarOrderResponse): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarRequest' from backend` + '\x1b[0m', order);
      // this.validateReceivedData(order, 'avatarRequest');
      this.avatarEvent.next({
        type: 'request',
        order
      });
    });

    this.socket.on('avatarResponse', (data: EventAvatarResponse): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarResponse' from backend` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarResponse');
      this.avatarEvent.next({
        type: 'response',
        data
      });
    });

    this.socket.on('avatarCustomerReady', (data: EventAvatarCustomerReady): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCustomerReady'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCustomerReady');
      this.avatarEvent.next({
        type: 'customerReady',
        data
      });
    });

    this.socket.on('avatarOffer', (data: RTCSessionDescriptionInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarOffer'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarOffer');
      this.avatarEvent.next({
        type: 'offer',
        offer: data
      });
    });

    this.socket.on('avatarAnswer', (data: RTCSessionDescriptionInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarAnswer'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarAnswer');
      this.avatarEvent.next({
        type: 'answer',
        answer: data
      });
    });

    this.socket.on('avatarCandidate', (data: RTCIceCandidateInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCandidate'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCandidate');
      this.avatarEvent.next({
        type: 'candidate',
        candidate: data
      });
    });

    this.socket.on('avatarMessage', (command: string): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarMessage'` + '\x1b[0m', data);
      this.validateReceivedData(command, 'avatarMessage');
      this.avatarEvent.next({
        type: 'command',
        data: command
      });
    });

    this.socket.on('avatarPartnerConnectedStatus', (data: { connected: boolean, message: string }): void => {
      console.log('\x1b[35m' + `Socket event 'avatarPartnerConnectedStatus'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarPartnerConnectedStatus');
      this.avatarEvent.next({
        type: 'partnerConnectedStatus',
        data
      });
    });

    this.socket.on('avatarExit', (data: boolean): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarExit'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarExit');
      this.avatarEvent.next({
        type: 'exit',
        data
      });
    });

    this.socket.on('avatarExitTimeout', (data: boolean): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarExitTimeout' from backend` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarExitTimeout');
      this.avatarEvent.next({
        type: 'exitTimeout',
        data
      });
    });

    this.socket.on('avatarRequestReconnect', (data: EventAvatarRequestReconnect): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarRequestReconnect'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarRequestReconnect');
      this.avatarEvent.next({
        type: 'requestReconnect',
        request: data
      });
    });

    this.socket.on('avatarCustomerReadyReconnect', (data: EventAvatarCustomerReadyReconnect): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCustomerReadyReconnect'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCustomerReadyReconnect');
      this.avatarEvent.next({
        type: 'customerReadyReconnect',
        response: data
      });
    });

    this.socket.on('avatarZoom', (zoom: number): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarZoom'` + '\x1b[0m', zoom);
      this.validateReceivedData(zoom, 'avatarZoom');
      this.avatarEvent.next({
        type: 'zoom',
        zoom
      });
    });

    this.socket.on('avatarStats', (bitrate: string): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarStats'` + '\x1b[0m', bitrate);
      this.validateReceivedData(bitrate, 'avatarStats');
      this.avatarEvent.next({
        type: 'stats',
        bitrate
      });
    });

    this.socket.on('eventForDev', (data: any): void => { // developer-only
      // console.log('\x1b[35m' + `Socket event 'eventForDev'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'eventForDev');
      this.avatarEvent.next({
        type: 'eventForDev',
        data
      });
    });

    this.socket.on('avatarViewerReady', (data: EventAvatarViewerReady): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarViewerReady'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarViewerReady');
      this.avatarEvent.next({
        type: 'viewerReady',
        data
      });
    });

    this.socket.on('avatarStreamerReady', (data: EventAvatarStreamerReady): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarStreamerReady'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarStreamerReady');
      this.avatarEvent.next({
        type: 'streamerReady',
        data
      });
    });
  }

  //#region Validate data

  private validateReceivedData(eventData: Object, eventName: string): void {
    if (!eventData && eventData !== 0) console.error(`Received incorrect socket data from event '${eventName}'!`);
    this.validateObjectFields(eventData, `received from event '${eventName}'`);
  }

  private validateEmitData(eventData: Object, eventName: string): void {
    if (!eventName) console.error(`Incorrect event name!`);
    if (!eventData) console.error(`Incorrect sending data for the event '${eventName}'!`);
    this.validateObjectFields(eventData, `for emit event '${eventName}'`);
  }

  private validateObjectFields(obj: Object, eventDetails: string): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined) {
          console.error(`Incorrect '${key}' ${eventDetails}`);
        }
      }
    }
  }

  //#endregion Validate data
}
