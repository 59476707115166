import { EventEmitter, Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import { UserMarkerCustomData } from '../avatar/gmap/gmap.interfaces';

export interface IEventBusMessage {
  /**
   * Key to identify message.
   */
  key: string;

  /**
   * Data associated to message.
   */
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public checkNewTips: Subject<boolean> = new Subject();
  public goToUserOnMap: EventEmitter<UserMarkerCustomData> = new EventEmitter<UserMarkerCustomData>();

  /**
   * Key message separator.
   */
  private separator: string = ':';
  private readonly channels: Subject<IEventBusMessage>;

  constructor() {
    this.channels = new Subject<IEventBusMessage>();
  }

  /**
   * Subscribe to a topic and provide a single handler/observer.
   * @param key Key to identify the message/event.
   *
   * @returns Subscription from which you can unsubscribe to release memory resources and to prevent memory leak.
   */
  on(key: string): Observable<IEventBusMessage> {
    return this.channels.asObservable().pipe(
      filter((channel: IEventBusMessage): boolean => this.keyMatch(channel.key, key)),
    );
  }

  /**
   * Publish some data to the subscribers of the given topic.
   * @param key Key to identify the message/event.
   * @param data Optional: Additional data sent with the message/event.
   */
  publish(key: string, data?: any): void {
    if (!key.trim().length) {
      throw new Error('key parameter must be a string and must not be empty');
    }

    const value: IEventBusMessage = {key, data};
    this.channels.next(value);
  }

  /**
   * When you are sure that you are done with the topic and the subscribers no longer needs to listen to a particular topic, you can
   * destroy the observable of the topic using this method.
   * @param key The name of the topic to destroy.
   */
  destroy(key: string): null {
    const subject = this.channels[key];

    if (!subject) return;

    subject.complete();
    delete this.channels[key];
  }

  /**
   * Validates key matching.
   *
   * @param  key Key to identify the message/event.
   * @param wildcard Wildcard received from on method.
   *
   * @return true if key matches, false otherwise.
   */
  public keyMatch(key: string, wildcard: string): boolean {
    const w: string = '*';
    const ww: string = '**';

    const partMatch = (wl: string, k: string): boolean => {
      return wl === w || wl === k;
    };

    const sep: string = this.separator;
    const kArr: string[] = key.split(sep);
    const wArr: string[] = wildcard.split(sep);

    const kLen: number = kArr.length;
    const wLen: number = wArr.length;
    const max: number = Math.max(kLen, wLen);

    for (let i: number = 0; i < max; i++) {
      const cK: string = kArr[i];
      const cW: string = wArr[i];

      if (cW === ww && typeof cK !== 'undefined') return true;

      if (!partMatch(cW, cK)) return false;
    }

    return true;
  }
}
