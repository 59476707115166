import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { ResetData } from '../../interfaces';
// import { LoadingService } from '../../services/loading.service';
// import { ToastService } from '../../services/toast.service';
// import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {

  resetForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    // private authService: AuthService,
    // private router: Router,
    // private toastService: ToastService,
    // private loadingService: LoadingService,
  ) {
  }

  ngOnInit() {
    this.resetForm = this.fb.group({
      phone: [history.state.phone, [Validators.required, Validators.pattern('[+0-9]{12}')]],
      code: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  reset(): void {
    console.warn('Not implemented');
    // if (this.resetForm.invalid) {
    //   return;
    // }
    //
    // this.loadingService.start().then();
    //
    // const resetData: ResetData = {
    //   phone: this.resetForm.value.phone,
    //   code: this.resetForm.value.code,
    //   password: this.resetForm.value.password,
    // };

    // this.authService.reset(resetData)
    //   .subscribe(async (success) => {
    //     await this.toastService.success(success.message);
    //     this.router.navigate(['login']).then();
    //   }, async (errorText) => {
    //     await this.toastService.error(errorText);
    //   }).add(() => {
    //     this.loadingService.stop().then();
    //   });
  }
}
